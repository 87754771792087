import { render, staticRenderFns } from "./KpiLinkCreate.vue?vue&type=template&id=9c08da9e"
import script from "./KpiLinkCreate.vue?vue&type=script&lang=js"
export * from "./KpiLinkCreate.vue?vue&type=script&lang=js"
import style0 from "./KpiLinkCreate.vue?vue&type=style&index=0&id=9c08da9e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports